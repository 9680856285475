
import React from 'react'
import Image from 'next/image'
import LogoImage from '@/public/luvi-new-logo.svg'

const Logo = () => {
    return (
        <Image
        src={LogoImage}
        width='160'
        height='80'
        alt='Logo'
        />
    )
}

export default Logo
